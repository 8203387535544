import gifWeArePresentsSpanish from "../img/gif_colombia.gif";
import gifWeArePresentsEnglish from "../img/gif_colombia_EN.gif";

// COLOMBIA PLANT
import banner1Colombia from "../img/banner1_colombia.png";
import banner2Colombia from "../img/banner2_colombia.png";
import banner3Colombia from "../img/banner3_colombia.png";

// COSTA RICA PLANT
import banner1CostaRica from "../img/banner1_costa_rica.png";
import banner2CostaRica from "../img/banner2_costa_rica.png";
import banner3CostaRica from "../img/banner3_costa_rica.png";
import {
  HIGHER_PURPOSE_ITEMS_COLOMBIA,
  HIGHER_PURPOSE_ITEMS_COSTA_RICA,
} from "../../HigherPurpose/constants";
import {
  TRANSFORM_LIFES_IMAGES_COLOMBIA,
  TRANSFORM_LIFES_IMAGES_COSTA_RICA,
} from "../../TransformlifesSection/constants";
import { FormattedMessage } from "react-intl";

export const COLOMBIA_PLANT = "colombia";
export const COSTA_RICA_PLANT = "costa-rica";

const COLOMBIA_COMPANY = {
  title: "Proquinal® Colombia:",
  description: (
    <FormattedMessage
      id="CompanyPlants.description.proquinalColombia"
      description="Company plants proquinal colombia description"
      defaultMessage="<strong>65 Years </strong>of constant innovation"
      values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
      }}
    />
  ),
};

const COSTA_RICA_COMAPANY = {
  title: "Proquinal® Costa Rica",
  description: (
    <FormattedMessage
      id="CompanyPlants.description.proquinalCostaRica"
      description="Company plants proquinal costa rica description"
      defaultMessage="<strong>20 Years </strong>sustainable leadership in the region"
      values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
      }}
    />
  ),
};

export const COMPANY_PLANTS = {
  [COLOMBIA_PLANT]: {
    bannerInfo: [
      {
        bannerId: 1,
        img: banner1Colombia,
        ...COLOMBIA_COMPANY,
      },
      {
        bannerId: 2,
        img: banner2Colombia,
        ...COLOMBIA_COMPANY,
      },
      {
        bannerId: 3,
        img: banner3Colombia,
        ...COLOMBIA_COMPANY,
      },
    ],
    plantInfo: {
      plantDescription: (
        <FormattedMessage
          id="CompanyPlants.plantInfo.description.proquinalColombia"
          defaultMessage="Since 1959, we have been an engine of economic and social development in Colombia. With more than 900 professionals, in a Great Place to Work certified space, we create fabrics and coated floors that reflect our unwavering commitment to building and strengthening the country, prioritizing sustainable excellence and an inspiring work environment for all."
        />
      ),
      metricDescription: (
        <FormattedMessage
          id="CompanyPlants.metricDescription.proquinalColombia"
          defaultMessage=" "
        />
      ),
      weArePresentsIn: { 
        es: gifWeArePresentsSpanish,
        en: gifWeArePresentsEnglish
      }
    },
    higherPurposes: HIGHER_PURPOSE_ITEMS_COLOMBIA,
    transformLifes: TRANSFORM_LIFES_IMAGES_COLOMBIA,
  },
  [COSTA_RICA_PLANT]: {
    bannerInfo: [
      {
        bannerId: 1,
        img: banner1CostaRica,
        ...COSTA_RICA_COMAPANY,
      },
      {
        bannerId: 2,
        img: banner2CostaRica,
        ...COSTA_RICA_COMAPANY,
      },
      {
        bannerId: 3,
        img: banner3CostaRica,
        ...COSTA_RICA_COMAPANY,
      },
    ],
    plantInfo: {
      plantDescription: (
        <FormattedMessage
          id="CompanyPlants.plantDescription.proquinalCostaRica"
          defaultMessage="At the Costa Rica plant, innovation and sustainability converge in a 30,000 m² space certified as a Great Place to Work. With more than two decades of experience and 10 years of Carbon Neutral certification, we are pioneers in the manufacture of local products of international quality. Day by day we move for our people and the environment that surrounds us."
          values={{
            br: <br />
          }}
        />
      ),
      metricDescription: (
        <FormattedMessage
          id="CompanyPlants.metricDescription.proquinalCostaRica"
          defaultMessage=" "
        />
      ),
      weArePresentsIn: { 
        es: gifWeArePresentsSpanish,
        en: gifWeArePresentsEnglish
      }
    },
    higherPurposes: HIGHER_PURPOSE_ITEMS_COSTA_RICA,
    transformLifes: TRANSFORM_LIFES_IMAGES_COSTA_RICA,
  },
};
