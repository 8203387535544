import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { COMPANY_PLANTS } from './components/Banner/constants/bannerItems';
import Banner from './components/Banner';
import CertificationsSection from './components/CertificationsSection';
import HigherPurpose from './components/HigherPurpose';
import OurEssenceSection from './components/OurEssenceSection';
import PlantDescription from './components/PlantDescription';
import NoMatch from 'app/components/NoMatch';
import TransformLifesSection from './components/TransformlifesSection';
import { isMARINE, isRESOURCES, isUSA } from 'services/instances';
import { Redirect } from "react-router-dom";

const CompanyPlantsPage = ({ match }) => {
  const { plantSlug } = match.params;

  const {
    bannerInfo,
    plantInfo,
    higherPurposes
  } = COMPANY_PLANTS[plantSlug];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
    
  if (!Object.prototype.hasOwnProperty.call(COMPANY_PLANTS, plantSlug))
    return <NoMatch />;

  if (isRESOURCES()) 
    return <Redirect to="/page-404" />
 
  return (
    <>
      <Banner items={bannerInfo} />
      <PlantDescription description={plantInfo} />
      <OurEssenceSection plantSlug={plantSlug} />
      <HigherPurpose purposesItems={higherPurposes} />
      <CertificationsSection currentPlant={plantSlug} />
      <TransformLifesSection slug={plantSlug} />
    </>
  );
};

CompanyPlantsPage.propTypes = {
  match: PropTypes.object,
};

export default CompanyPlantsPage;