import { useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import PatternContext from "modules/products/ProductDetailsPage/contexts/PatternContext";
import { useMutation } from "@apollo/react-hooks";
import addToCartMutation from "modules/products/ProductDetailsPage/components/ProductHeader/components/ProductHeaderActions/mutation";
import { toast } from "react-toastify";
import IconCatalogue from "modules/cart/scenes/SamplesCart/components/SamplesList/components/RequestedSampleCard/components/IconCatalogue";
import PatternAddedToast from "modules/products/ProductDetailsPage/components/ProductHeader/components/ProductHeaderActions/components/PatternAddedToast";
import TranslatedLink from "routes/components/TranslatedLink";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { CONTACT_FOR_SAMPLES, STORE_LOCATIONS } from "routes";
import {
  isMEXICO,
  isLATAM,
  isMARINE,
  getTranslatedLink,
  getCurrentInstanceId,
} from "services/instances";
import { CONTACT_FOR_SAMPLES_REQUEST_MSGS } from "./constants";

export default function ProductHeaderActions({
  selectedReference,
  cartId,
  setCartId,
  hideRequestCatalog,
  hiderequestSample,
}) {
  const pattern = useContext(PatternContext);
  const hideProductHeaderActions = isLATAM() || isMEXICO() || isMARINE();
  const showContactForSamples = isLATAM() || isMEXICO() || isMARINE();
  
  const showWhereToBuy = !!pattern.stores.totalCount;
  const isDiferentToLATAM = isLATAM() ? false : true;

  const [addToCart, { loading }] = useMutation(addToCartMutation, {
    onCompleted(data) {
      const { item } = data.addToCart;
      if (item.product !== null)
        toast(
          <PatternAddedToast
            image={
              <img
                src={selectedReference.imageThumbSmall}
                alt={selectedReference.color}
              />
            }
            name={`${pattern.name}, ${selectedReference.color}`}
          />
        );
      else
        toast(
          <PatternAddedToast image={<IconCatalogue />} name={pattern.name} />
        );
      setCartId(item.cart.id);
    },
    onError(error) {
      error.graphQLErrors.forEach((error) => toast.error(error.message));
    },
  });

  let mutationVariables = {
    unitPrice: 0,
    quantity: 1,
  };

  if (cartId) mutationVariables["cartId"] = cartId;

  /**
   * Handles click on the sample button
   */
  function handleSampleBtnClick() {
    addToCart({
      variables: {
        input: {
          ...mutationVariables,
          productId: selectedReference.id,
          instanceId: getCurrentInstanceId(),
        },
      },
      refetchQueries: ["Cart"],
    });
  }

  /**
   * Handles click on the catalog button
   */
  function handleCatalogBtnClick() {
    addToCart({
      variables: {
        input: {
          ...mutationVariables,
          patternId: pattern.id,
          instanceId: getCurrentInstanceId(),
        },
      },
      refetchQueries: ["Cart"],
    });
  }

  return (
    <>
      <div className="row no-gutters justify-content-lg-center">
        {!hideProductHeaderActions && !hiderequestSample && (
          <div className="col-6 col-lg-auto text-center">
            <button
              onClick={handleSampleBtnClick}
              className="btn btn-light"
              disabled={loading || !selectedReference}
            >
              <FormattedMessage
                id="ProductHeader.requestSampleButton"
                description="Label for request sample action button in Product details Page"
                defaultMessage="Solicitar una muestra"
              />
            </button>
          </div>
        )}
        {!hideProductHeaderActions && !hideRequestCatalog && (
          <div className="col-6 col-lg-auto text-center">
            <button
              onClick={handleCatalogBtnClick}
              className="btn btn-light"
              disabled={loading}
            >
              <FormattedMessage
                id="ProductHeader.requestCatalogButton"
                description="Label fot request catalog action button in Product details Page"
                defaultMessage="Solicitar un catálogo"
              />
            </button>
          </div>
        )}

        {/* Se oculta este boton solo para la instacia de latam "la" */}
        {showWhereToBuy && isDiferentToLATAM && (
          <div className="col-6 col-lg-auto text-center">
            <Link
              className="btn btn-light"
              to={{
                pathname: getTranslatedLink(STORE_LOCATIONS, {}),
                search: `?${queryString.stringify({
                  productId: pattern.id,
                })}`,
              }}
            >
              <FormattedMessage
                id="DealerSearchEngine.label"
                description="Product details action button label"
                defaultMessage="Buscador de distribuidores"
              />
            </Link>
          </div>
        )}
        {showContactForSamples && (
          <div className="col-6 col-lg-auto text-center">
            <TranslatedLink
              className="btn btn-light"
              name={CONTACT_FOR_SAMPLES}
              params={{ patternSlug: pattern.slug }}
            >
              <FormattedMessage
                {...CONTACT_FOR_SAMPLES_REQUEST_MSGS[getCurrentInstanceId()]}
              />
            </TranslatedLink>
          </div>
        )}
      </div>
    </>
  );
}

ProductHeaderActions.propTypes = {
  cartId: PropTypes.string.isRequired,
  hideRequestCatalog: PropTypes.bool,
  hiderequestSample: PropTypes.bool,
  setCartId: PropTypes.func.isRequired,
  selectedReference: PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageThumbSmall: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
};
