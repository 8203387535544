import classNames from "classnames";
import "./styles/RenderSlides.scss";

const RenderSlides = ({
  numSlidersperPage,
  testimonialsItems,
  hoveredItem,
  isDesktop,
  handleHoveredItem,
  handleClickCurrentTestimonial 
}) => {
  const slides = [];
  const numSlides = numSlidersperPage;

  for (let i = 0; i < testimonialsItems.length; i += numSlides) {
    slides.push(
      <div key={i} className="flicking-panel custom-class-width-slider p-0 col-12 col-lg-12 d-flex flex-nowrap justify-content-between testimonial--slide-container">
        {testimonialsItems.slice(i, i + numSlides).map(({ node }) => {
          const { id, imageUrl, description, title } = node
          const specificAdjustToImageHeight = title?.toLowerCase()?.includes("rachel") // specific case to not crop the image too much

          return (
            <div
              key={id}
              className={classNames({
                "relative transition-all d-flex justify-content-center flex-grow-1 overflow-hidden rounded-2xl": true,
                "hover-w-50": hoveredItem === id && isDesktop,
                "aspect-video-vertical heigth500 h-100": !isDesktop,
                "testimonial-slide--content": isDesktop
              })}
              onMouseEnter={() => handleHoveredItem(id)}
              onMouseLeave={() => handleHoveredItem(null)}
              onClick={() => handleClickCurrentTestimonial(node)}
            >
              <img
                className={classNames({
                  "testimonial-slide--img rounded-2xl px-1": true,
                  "custom-img--height": specificAdjustToImageHeight
                })}
                src={imageUrl}
                alt={"testimonial-image"}
              />
              <div 
                className={classNames({
                  "d-none": !description || (isDesktop && hoveredItem !== id),
                  "d-flex flex-column": hoveredItem === id && description && isDesktop,
                  "position-absolute testimonial-slide--desc-container bg-gray-400 bg-opacity-50 text-white p-2 rounded-2xl transition-all": true,
                  'opacity-100': hoveredItem === id || !isDesktop,
                  'opacity-0': hoveredItem !== id && isDesktop,
                  'bottom-6': hoveredItem === id && isDesktop,
                  'bottom-0': hoveredItem !== id || !isDesktop
                })}
              >
                <p 
                  className="testimonial-slide--description text-left p-0 m-0" 
                  dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          )
        })}
      </div>
    );
  }

  return slides;
};

export default RenderSlides;