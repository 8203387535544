import PropTypes from "prop-types";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import useMediaQuery from "utils/hooks/useMediaQuery";
import useGetCertificatesPlants from "./hooks/useGetCertificatesPlants";
import { CARBONO_NEUTRAL, CLASSNAMES_LOGO_CERTIFICATIONS } from "./constants";
import { dynamicWidthAccordingCurrentPlant } from "./constants/dynamicWidth";
import "./styles/CertificationsSection.scss";
import { FormattedMessage } from "react-intl";
import { getCurrentLanguageAndInstanceCode } from "services/instances";
import carbonNeutralLogoEN from "./img/carbon-neutral.faf8f447.svg";

const CertificationsSection = ({ currentPlant }) => {
  const [lang] = getCurrentLanguageAndInstanceCode();
  const isMobile = useMediaQuery("(max-width: 995px)");
  const { certificatesPlants } = useGetCertificatesPlants((`proquinal-${currentPlant ? currentPlant : ""}`));

  return (
    <div className='container mt-5 mb-5 pt-5 px-0'>
      <TitleUnderlined hTag={H2_TAG} className="mb-5">
        <FormattedMessage 
          id="CompanyPlants.certificationsSection.title.certifications"
          defaultMessage="Certifications"
        />
      </TitleUnderlined>
      <section className='container-certifications d-flex flex-wrap px-0 justify-content-center justify-content-lg-between'>
        {certificatesPlants.map(({ node }, index) => {
          const widthCurrentRow = dynamicWidthAccordingCurrentPlant(currentPlant, isMobile, index);

          // DRAFT/TEMPORAL IMPLEMENTATION TO MANAGE THE CARBONO NEUTRAL LOGO IN ENGLISH
          const isCarbonNeutralLogo = node.title === CARBONO_NEUTRAL
          const carbonNeutralENClassname = isCarbonNeutralLogo && lang === "en" && "carbono-neutral-certificate-en"
          if (isCarbonNeutralLogo) {
            node.imageThumb = lang === "es" ? node.imageThumb : carbonNeutralLogoEN
          }

          return <div
            key={node.id} 
            className='certificate-container-img pb-2 md:pb-5 pb-lg-0 d-flex justify-content-center justify-content-lg-start align-items-center'
            style={{ width: widthCurrentRow }}
            >
            <img
              className={`company--plants-certification-img ${CLASSNAMES_LOGO_CERTIFICATIONS[node.title]} ${carbonNeutralENClassname}`} 
              src={node.imageThumb} 
              alt={`${node.title}-image`} 
            />
          </div>
        })}
      </section>
    </div>
  );
};

CertificationsSection.propTypes = {
  currentPlant: PropTypes.string
}

export default CertificationsSection;