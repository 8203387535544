/**
 * Checks if the testimonial subject's name contains specific keywords
 * ("rachel" or "jarret") to determine whether a custom aspect ratio should be applied.
 *
 * @param {string | undefined} subjectTestimonialName - The name of the testimonial subject. 
 *        It can be a string or undefined if no name is provided.
 *
 * @returns {boolean} - Returns `true` if the name includes "rachel" or "jarret" (case-insensitive),
 *         and `false` otherwise.
 */
export const handleCustomSubjectClassname = (subjectTestimonialName) => {
  const customClassnames = [
    {
      name: "rachel",
      className: "custom-rachel-aspect-ratio--img"
    },
    {
      name: "jarret",
      className: "custom-aspect-ratio"
    }
  ];

  const customSubjectClassname = customClassnames.filter(subject => subjectTestimonialName?.toLowerCase().includes(subject.name))[0];

  return customSubjectClassname?.className ?? ""
}